import { render, staticRenderFns } from "./List.vue?vue&type=template&id=c476b9b6&scoped=true&"
import script from "./List.vue?vue&type=script&lang=js&"
export * from "./List.vue?vue&type=script&lang=js&"
import style1 from "../style.css?vue&type=style&index=1&id=c476b9b6&prod&scoped=true&lang=css&"
import style2 from "./List.vue?vue&type=style&index=2&id=c476b9b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c476b9b6",
  null
  
)

export default component.exports